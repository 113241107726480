<template>
  <div>
    <!-- ---------- -->

    <div class="footer_warp">
      <div class="footer_cont">
        <!-- 上部 -->
        <div class="footer_cont_l">
          <!--上部 左 -->
          <div class="footer_cont_l_div">
            <div class="footer_cont_l_div_item">
              <p class="footer_cont_l_title">PRODUCTS</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList2"
                  :key="index"
                  @click="toProduct(index)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- top 中 -->
        <div class="footer_cont_c">
          <div>
            <img
              style="width: 80px; height: 40px"
              src="../assets/img/productImg/logoPic.png"
              alt=""
            />
            <img
              style="width: 180px; height: 40px"
              src="../assets/img/productImg/logoContent.png"
              alt=""
            />
          </div>
          <div>
            <p class="address">
              Guangzhou Sunny Auto Parts Co., Ltd<br />
              address：No. 41 Lianquan Road, Yuexiu District, Guangzhou, China
              510030<br />
              email：business@gzsap-cn.com, bgliao@126.com
            </p>
          </div>
        </div>
        <!-- top 右 -->
        <div class="footer_cont_r">
          <div class="concatImg">
            <div class="imgItem" style="margin-right: 10px">
              <img src="../assets/img/wechat.png" alt="" />
              <span>wechat</span>
            </div>
            <div class="imgItem">
              <img src="../assets/img/whatsApp.png" alt="" />
              <span>whatsApp</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 下部 -->
      <!-- <div class="footer_warp_btm">
				<p>
					Copyright
					©&ensp;&ensp;xxxx有限公司&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;#网站备案号#
				</p>
			</div> -->
    </div>

    <!-- --------- -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      arrList2: [
        "Fog Lamp & Cover",
        "Side Mirror",
        "Other Auto Lamp",
        "Fender Liner",
        "Other Parts",
      ],
    };
  },

  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },

  methods: {
    toProduct(index) {
      this.$router.push({
        path: "/jituangaikuo",
        query: {
          index,
        },
      });
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.footer_warp {
  width: 100vw;
  height: 388px;
  background: #638f4e;
}
.footer_cont {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.footer_cont_l {
  min-width: 200px;
  font-size: 16px;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  padding-top: 77px;
}
.footer_cont_l_title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  width: 64px;
}
.footer_cont_l_ul {
  margin-top: 36px;
}
.footer_cont_l_ul li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #eee;
  margin-top: 10px;
  cursor: pointer;
}
.footer_cont_l_div {
  display: flex;
}
.footer_cont_l_div_item {
  margin-right: 72px;
}
.footer_cont_c {
  margin-top: 70px;
  margin-left: 80px;
}
.address {
  /* width: 467px; */
  font-size: 16px;
  font-family: "PingFangSC-Regular", "PingFang SC";
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  margin-top: 42px;
}
.footer_cont_r {
  margin-top: 79px;
  margin-left: 70px;
}
.footer_cont_r .concatImg {
  flex: 1;
  display: flex;
  margin-right: 50px;
  justify-content: flex-end;
}
.footer_cont_r .concatImg img {
  width: 100px;
  height: 100px;
}
.imgItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 40px; */
}
.footer_cont_r p {
  text-align: center;
  font-size: 14px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 17px;
}
.footer_cont_r_border {
  width: 111px;
  height: 109px;
  border: 1px solid #979797;
}
.footer_cont_r_border img {
  width: 95px;
  height: 95px;
  margin: 7px 8px;
}
.footer_warp_btm {
  width: 1280px;
  margin: 0 auto;
  height: 65px;
  margin-top: 56px;

  border-top: 1px solid #444444;
}
.footer_warp_btm p {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 48px;
}
</style>
