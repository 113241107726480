import axiosIns from '@/plugins/axios'

// 登录
export function loginIn(data) {
    return axiosIns({
        url: `/user/login`,
        method: 'post',
        data
    })
}
// 退出登录
export function logout (data) {
    return axiosIns({
        url: `/user/logout`,
        method: 'post',
        data
    })
}
export function setUserPwd (data) {
    return axiosIns({
        url: `/user/setUserPwd`,
        method: 'post',
        data
    })
}
// 查询产品分页数据
export function getPage(data) {
    return axiosIns({
        url: `/product/getPage`,
        method: 'post',
        data
    })
}
// 新增产品 
export function addProduct(data) {
    return axiosIns({
        url: `/product/addProduct`,
        method: 'post',
        data
    })
}
// 更新产品 
export function updateProduct(data) {
    return axiosIns({
        url: `/product/updateProduct`,
        method: 'put',
        data
    })
}
// 删除产品 
export function deleteProduct(id) {
    return axiosIns({
        url: `/product/deleteProduct/${id}`,
        method: 'delete'
    })
}
// 根据id获取产品
export function getProductById(id) {
    return axiosIns({
        url: `/product/getProductById/${id}`,
        method: 'get'
    })
}
// 图片上传接口
export function uploadImg(data) {
    return axiosIns({
        url: `/picture/upload`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}
// 多图片上传接口
export function multipleUploadImg(data) {
    return axiosIns({
        url: `/picture/multipleUpload`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}
// 图片删除接口
export function deleteImg(data) {
    return axiosIns({
        url: `/picture/delete`,
        method: 'delete',
        params: data
    })
}
// 图片下载接口
export function downloadImg(fileName) {
    return axiosIns({
        url: `/picture/download/${fileName}`,
        method: 'get'
    })
}
// 获取首页文字
export function getDictById() {
    return axiosIns({
        url: `/dict/getDictById/3`,
        method: 'get'
    })
}
// 获取首页图片
export function getDictKeyValue(data) {
    return axiosIns({
        url: `/dict/getDictKeyValue`,
        method: 'post',
        data
    })
}
// 更新首页文字
export function updateDict(data) {
    return axiosIns({
        url: `/dict/updateDict`,
        method: 'put',
        data
    })
}
// 删除首页文字
export function deleteDictKeyValue(data) {
    return axiosIns({
        url: `/dict/deleteDictKeyValue/${data}`,
        method: 'delete'
    })
}
// 添加首页文字
export function addDictKeyValue(data) {
    return axiosIns({
        url: `/dict/addDictKeyValue`,
        method: 'post',
        data
    })
}
